.footer {
    width: 100%;
    display: flex;
    padding: 1rem 0;
    align-items: center;
    justify-content: center;
    background-color: #F1F1F1;

    p {
        margin: 0;
        font-size: 15px;
        font-weight: 500;
        color: #878787;
    }

}


/* For screens 991px and below */

// @media (max-width: 991px) {
  
//     .footer {
//           padding-top: 1%;
//           margin: auto;
//           position: relative;
//           left: 100px;
//     }

  
// }

// @media (max-width: 767px) {
  
//       .footer {
//             padding-top: 10%;
//             left: 200px;
//       }
// }

// @media (max-width: 540px) {
  
//     .footer {
//           padding-top: 10%;
//           left: 200px;
//           padding-left: 10%;
//           width: 115%;
//     }
// }