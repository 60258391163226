.addItem {
    top: 0;
    z-index: 9;
    opacity: 0;
    right: 1rem;
    width: 900px;
    height: 10rem;
    padding: 1rem;
    overflow-y: hidden;
    position: absolute;
    visibility: hidden;
    border-radius: 20px;
    background: #FFFFFF;
    box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.25);
    transition: height 0.3s opacity 0.3s, visibility 0.3s;

    .padding {
        border-radius: 10px 10px 0 0;
        border: 1px solid rgba(0, 0, 0, 0.50);
    }

    .header {
        padding: 1.5rem 1rem;
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;

        p {
            margin: 0;
            font-size: 30px;
            font-weight: 600;
            color: #000000;
        }

        .close {
            top: 1rem;
            right: 1rem;
            z-index: 99;
            width: 13px;
            height: 13px;
            cursor: pointer;
            position: absolute;
        }
    }

    .body {
        margin-top: 0;
        display: flex;
        flex-direction: column;

        input {

            &:active,
            &:focus {
                box-shadow: none;
            }
        }

        .hr {
            margin: 0;
            width: 100%;
        }

        .centerGroup {
            margin: auto;
            width: 20rem;
            padding: 0.5rem 0;

            input {
                border: none;
                border-radius: 0;
                font-size: 14px;
                text-align: center;
                border-bottom: solid 1px #000;

                &::placeholder {
                    font-size: 14px;
                    font-style: italic;
                }
            }

            label {
                width: 100%;
                font-size: 14px;
                text-align: center;
                margin: 10px 0 0;
            }
        }

        .formHeader {
            display: flex;
            border-top: 1px solid #B1B1B1;
            border-bottom: 1px solid #B1B1B1;

            .line {
                width: 1px;
                height: unset;
                background-color: #B1B1B1;
            }

            .col {
                flex: 1;
                padding: 1rem;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }

            .group {
                display: flex;
                align-items: center;

                .selectContainer {
                    flex: 2;
                }

                input {
                    flex: 2;
                    border: none;
                    font-size: 14px;
                    border-radius: 0;
                    text-align: center;
                    border-bottom: solid 1px #000;

                    &::placeholder {
                        font-size: 14px;
                        font-style: italic;
                    }
                }

                label {
                    flex: 1;
                    margin: 0;
                    width: 100%;
                    font-size: 14px;
                    text-align: left;
                }
            }

            .dates {
                gap: 1rem;
                display: flex;
            }
        }

        .form {
            display: flex;

            table {
                margin: 0;
                border: none;
                table-layout: fixed;

                thead {
                    tr {
                        border-bottom: solid 1px #B1B1B1;

                        th {
                            border: none;
                            width: 16.67%;
                            font-size: 12px;
                        }
                    }
                }

                tbody {
                    tr {
                        border: none;

                        td {
                            border: none;

                            .addRow {
                                gap: 5px;
                                display: flex;
                                font-size: 14px;
                                padding: 3px 7px;
                                align-items: center;
                                border-radius: 18px;
                                background: #03A900;
                                border-color: #03A900;
                            }

                            input {
                                border: none;
                                font-size: 14px;
                                text-align: left;
                                border-radius: 0;
                                border-bottom: solid 1px #000;

                                &::placeholder {
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .purpose {
            padding: 1rem;

            .group {
                display: flex;
                align-items: center;

                input {
                    border: none;
                    font-size: 14px;
                    border-radius: 0;
                    text-align: left;
                    border-bottom: solid 1px #000;

                    &::placeholder {
                        font-size: 14px;
                        font-style: italic;
                    }
                }

                label {
                    flex: 1;
                    margin: 0;
                    width: 100%;
                    font-size: 14px;
                    text-align: left;
                }
            }
        }

        .signatures {
            gap: 1rem;
            display: flex;
            align-content: center;

            .left,
            .right {
                flex: 1;
                padding: 1rem;

                p {
                    text-align: center;
                }

                .group {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;

                    &.marginB {
                        margin-bottom: 1rem;
                    }

                    input {
                        border: none;
                        font-size: 14px;
                        border-radius: 0;
                        text-align: center;
                        border-bottom: solid 1px #000;

                        &::placeholder {
                            font-size: 14px;
                            font-style: italic;
                        }
                    }

                    label {
                        flex: 1;
                        margin: 0;
                        width: 100%;
                        font-size: 14px;
                        text-align: center;
                    }

                    .input {
                        width: 100%;
                        display: flex;
                        position: relative;
                        align-items: center;

                        input {
                            background-color: transparent;
                        }

                        .addButton {
                            .addCircle {
                                width: 1rem;
                                height: 1rem;
                                cursor: pointer;
                                fill: white;
                                width: 1rem;
                                height: 1rem;
                                border-radius: 50%;
                                background-color: #03A900;
                            }
                        }

                        .signatureImage {
                            left: 0;
                            right: 0;
                            z-index: -1;
                            width: 7rem;
                            margin: auto;
                            position: absolute;
                        }

                        .requestedBy,
                        .approvedBy {
                            width: 100%;
                            margin: 0 0 1px;
                        }
                    }
                }
            }
        }

        .remarks {
            padding: 1rem;

            label {
                font-size: 14px;
                font-weight: bold;
            }

            textarea {
                font-size: 14px;
                background: #F3F3F3;
            }
        }

        .approved {
            background: rgba(3, 169, 0, 0.21);

            p {
                margin: 0;
                padding: 1rem 0;
                color: #03A900;
                font-weight: bold;
                text-align: center;
            }
        }

        .forApproval {
            background: #e0d90036;

            p {
                margin: 0;
                padding: 1rem 0;
                color: #C3A400;
                font-weight: bold;
                text-align: center;
            }
        }

        .rejected {
            background: rgba(255, 142, 142, 0.65);

            p {
                margin: 0;
                padding: 1rem 0;
                color: #FF0000;
                font-weight: bold;
                text-align: center;
            }
        }
    }

    .buttons {
        gap: 10px;
        margin: 0;
        display: flex;
        padding: 1rem;
        background: #E6E6E6;
        justify-content: center;

        button {
            width: 7rem;
            font-size: 14px;
        }

        .save {
            gap: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-color: #03A900;
            background-color: #03A900;
        }

        .cancel {
            gap: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-color: #FF5353;
            background-color: #FF5353;
        }
    }

    &.active {
        left: 0;
        right: 0;
        opacity: 1;
        margin: auto;
        height: auto;
        visibility: visible;
        transition: height 0.3s opacity 0.3s, visibility 0.3s;
    }
}