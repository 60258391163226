
.transaction {
  padding: 1rem 0;
  background-color: #F1F1F1;
  min-height: calc(100vh - 183px);

  

  .columm {
    position: relative;

    .profileContainer {
      margin-top: 1rem;
      border-radius: 10px;
      background-color: #fff;

      .header {
        padding: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #DCDCDC;

        .search {
          gap: 1rem;
          display: flex;
          align-items: center;

          .buttonContainer {

            button {
              padding: 5px 10px;
              border: none;
              font-size: 20px;
              font-weight: 600;
              color: #878787;
              position: relative;
              background-color: #8787872d;
              
              
              &.active {
                color: #022BFF;
                background-color: transparent;

                svg {
                  fill: #022BFF;
                }

                &::after {
                  content: '';
                  left: 0;
                  right: 0;
                  width: 100%;
                  height: 6px;
                  bottom: -18px;
                  position: absolute;
                  background-color: #022BFF;
                }
              }
            }

            
            @media only screen and (max-width: 767px) {
              button {
                padding: 0px;
                font-size: 18px;
              }
            
            }


          }

          .searchBar {
            position: relative;

            svg {
              top: 0;
              left: 10px;
              bottom: 0;
              margin: auto;
              position: absolute;
            }

            input {
              font-size: 10px;
              color: #878787;
              padding-left: 2rem;
              font-family: 'Poppins';
            }
          }
        }

        .filter {
          gap: 1rem;
          display: flex;
          align-items: center;

          .processItem {
            gap: 10px;
            display: flex;
            flex: 0 0 9rem;
            font-size: 13px;
            font-weight: 500;
            color: #FFFFFF;
            border-radius: 5px;
            align-items: center;
            background: #071E99;
          }

          .selectContainer {
            position: relative;

            .select {
              width: 6rem;
              cursor: pointer;
              appearance: none;
              color: #000000;
              box-shadow: none;
              border-radius: 5px;
              background: #ECECEC;
              font-size: 13px;
              font-weight: 500;
              color: #878787;

              &:active,
              &:focus {
                box-shadow: none;
                border: none;
              }
            }

            &::after {
              width: 9px;
              top: 0;
              bottom: 0;
              content: '';
              height: 6px;
              right: 15px;
              margin: auto;
              position: absolute;
              background-size: cover;
              background: url('../../assets/images/arrowdown.svg');
            }
          }

          .searchBar {
            position: relative;

            svg {
              top: 0;
              left: 10px;
              bottom: 0;
              margin: auto;
              position: absolute;
            }

            input {
              height: 34px;
              font-size: 10px;
              color: #878787;
              padding-left: 2rem;
              font-family: 'Poppins';
              font-size: small;

              &:active,
              &:focus {
                box-shadow: none;
              }
            }
          }

          @media only screen and (max-width: 991px) {
            .searchBar {
              left: 10px;
              width: 90%;
            }
          
          }
        }

        .selectContainer {
          position: relative;

          .select {
            width: 7rem;
            cursor: pointer;
            appearance: none;
            color: #000000;
            box-shadow: none;
            border-radius: 5px;
            background: #ECECEC;

            &:active,
            &:focus {
              box-shadow: none;
              border: none;
            }
          }

          &::after {
            width: 9px;
            top: 0;
            bottom: 0;
            content: '';
            height: 6px;
            right: 15px;
            margin: auto;
            position: absolute;
            background-size: cover;
            background: url('../../assets/images/arrowdown.svg');
          }
        }
      }

      .body {
        gap: 1.5rem;
        display: flex;
        padding: 1rem;
        flex-direction: column;
      }
    }
  }
}


@media (max-width: 1400px) {
  
  body {
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      
  
      .background-gray {
            padding-top: 10px;
            margin: auto;
            position: relative;
      }

    }
}

@media (max-width: 991px) {
  
  body {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    
    .transaction {
        margin: auto;
        position: relative;
        // left: 100px;
    }

  }
}


/* For screens 991px and below */
@media (max-width: 767px) {
  
  body {
    
    .transaction {
        left: 200px;
    }

  }
}


/* For screens 540px and below */
@media (max-width: 540px) {
  
  .transaction {
    width: 115%;
    padding-left: 18%;
    padding-right: 5%;
  }
}