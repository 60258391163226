.dashboard {
    padding: 1rem 0;
    background-color: #F1F1F1;
    .CardsTitle {
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        .title {
            h6 {
                color: #1E1E1E;
                font-size: 20px;
                font-weight: 500;
                line-height: 30px;
            }
    
            p {
                font-size: 15px;
                font-weight: 400;
                color: #878787;
                line-height: 22px;
            }
        }
        @media screen and (max-width: 767px) {
            flex-direction: column;
        }
    }
    .cards {
        gap: 1rem;
        display: flex;
        align-items: stretch;
        justify-content: flex-end;

        .card {
            gap: 10px;
            display: flex;
            padding: 1rem;
            border-radius: 10px;
            background: #FFFFFF;

            .image {
                width: 75px;
                height: 75px;
                display: flex;
                align-items: center;
                border-radius: 10px;
                background: #071E99;
                justify-content: center;
            }

            .text {
                p {
                    margin: 0;
                    font-size: 15px;
                    font-weight: 500;
                    color: #1E1E1E;
                }

                span {
                    font-weight: 600;
                    font-size: 30px;
                    color: #1E1E1E;
                }
            }
            @media screen and (max-width: 1200px) {
                flex-direction: column;
            }
        }
        @media screen and (max-width: 1200px) {
            justify-content: space-between;
        }
    }

    .overview {
        border-radius: 10px;
        background-color: #fff;

        .header {
            padding: 1rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #DCDCDC;

            h5 {
                margin: 0;
                font-size: 20px;
                font-weight: 600;
                color: #1E1E1E;
            }

            .selectContainer {
                position: relative;

                .select {
                    width: 7rem;
                    cursor: pointer;
                    appearance: none;
                    color: #000000;
                    box-shadow: none;
                    border-radius: 5px;
                    background: #ECECEC;

                    &:active,
                    &:focus {
                        box-shadow: none;
                        border: none;
                    }
                }

                &::after {
                    width: 9px;
                    top: 0;
                    bottom: 0;
                    content: '';
                    height: 6px;
                    right: 15px;
                    margin: auto;
                    position: absolute;
                    background-size: cover;
                    background: url('../../assets/images/arrowdown.svg');
                }
            }
        }

        .body {
            padding: 1rem;
        }
    }
}