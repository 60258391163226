.signature-body {
    padding: 1.5rem;

    .sigCanvas {
        width: 100%;
        height: 200px;
        border: solid 1px #999;
    }
}

.signature-buttons {
    button {
        border: none;
        font-size: 14px;

        &.save {
            background-color: #03A900;
        }

        &.cancel {
            background-color: #FF5353;
        }
    }
}