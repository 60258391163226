.horizontalLine {
    position: absolute;
    background: #B1B1B1;
    width: 100%;
    height: 1px;
    top: 0%;
}

.verticalLine {
    position: absolute;
    background: #B1B1B1;
    height: 100%;
    width: 1px;
    right: 50%;
    top: 0%;
}

.addItem {
    top: 0;
    z-index: 9;
    opacity: 0;
    right: 1rem;
    width: 900px;
    height: 10rem;
    padding: 1rem;
    overflow-y: hidden;
    position: absolute;
    visibility: hidden;
    border-radius: 20px;
    background: #FFFFFF;
    box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.25);
    transition: height 0.3s opacity 0.3s, visibility 0.3s;

    .padding {
        border-radius: 10px;
        border: 1px solid rgba(0, 0, 0, 0.50);
    }

    .header {
        padding: 1.5rem 1rem;
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;

        p {
            margin: 0;
            font-size: 30px;
            font-weight: 600;
            color: #000000;
        }

        .close {
            top: 1rem;
            right: 1rem;
            z-index: 99;
            width: 13px;
            height: 13px;
            cursor: pointer;
            position: absolute;
        }
    }

    .body {
        margin-top: 0;
        display: flex;
        flex-direction: column;

        input {

            &:active,
            &:focus {
                box-shadow: none;
            }
        }

        .hr {
            margin: 0;
            width: 100%;
        }

        .centerGroup {
            margin: auto;
            width: 20rem;
            padding: 0.5rem 0;
            border-bottom: #000;
            color: #1E1E1E;
            font-size: 13px;
            font-style: normal;
            line-height: normal;

            input {
                border: none;
                border-radius: 0;
                text-align: center;
                border-bottom: solid 1px #000;
                font-weight: 600;
            }

            label {
                width: 100%;
                text-align: center;
                margin: 10px 0 0;
                font-weight: 500;
            }
        }

        .formHeader {
            padding: 10px;
            border-bottom: 1px solid #B1B1B1;
            display: grid;
            grid-template-columns: auto auto auto auto;
            position: relative;
            color: #1E1E1E;
            font-size: 10px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;

            :nth-child(2) {
                grid-column-start: 1;
                grid-column-end: 4;
            }

            :nth-child(8) {
                grid-column-start: 1;
                grid-column-end: 5;
            }

            .group {
                gap: 1rem;
                display: flex;
                align-items: center;
                justify-content: stretch;

                label {
                    flex: 0 0 4rem;
                    margin: 0;
                }

                input {
                    border: none;
                    font-size: 13px;
                    border-radius: 0;
                    border-bottom: solid 1px #000;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    color: #1E1E1E;

                    &::placeholder {
                        font-size: 14px;
                        font-style: italic;
                        font-weight: normal;
                    }
                }
            }
        }

        .paddingTop {
            padding-top: 1rem;
        }

        .terms {
            padding: 1rem;
            border-bottom: 1px solid #B1B1B1;

            h6 {
                font-size: 14px;
                font-weight: normal;
            }

            p {
                margin: 0;
                font-size: 14px;
                text-align: center;
            }
        }

        .failure {
            padding: 1rem;

            h6 {
                font-size: 14px;
                font-weight: normal;
            }

            p {
                margin: 0;
                font-size: 14px;
                text-align: center;
            }
        }

        .form {
            display: flex;

            table {
                margin: 0;
                border: none;

                thead {
                    tr {
                        border-bottom: solid 1px #B1B1B1;

                        th {
                            border: none;
                            font-size: 14px;
                            white-space: nowrap;
                            background: #D3D3D3;
                            height: 43px;
                            text-align: center;
                        }
                    }
                }

                tbody {
                    tr {
                        border: none;

                        td {
                            border: none;
                            background: #F4F0F0;

                            input {
                                border: none;
                                font-size: 13px;
                                text-align: left;
                                border-radius: 0;
                                font-weight: 600;
                                text-align: center;
                                font-style: normal;
                                line-height: normal;
                                background: #F4F0F0;

                                &::placeholder {
                                    font-size: 14px;
                                    font-weight: normal;
                                }
                            }
                        }
                    }
                }
            }
        }

        .totalAmount {
            padding: 1rem;
            border-bottom: 1px solid #B1B1B1;

            .group {
                display: flex;
                align-items: center;

                input {
                    border: none;
                    font-size: 14px;
                    border-radius: 0;
                    text-align: left;
                    border-bottom: solid 1px #000;

                    &::placeholder {
                        font-size: 14px;
                        font-style: italic;
                        font-weight: normal;
                    }
                }

                label {
                    flex: 0 0 10rem;
                    margin: 0;
                    width: 100%;
                    font-size: 14px;
                    text-align: left;
                }
            }
        }

        .resolution {
            padding: 1rem 1rem 0;

            .group {
                display: flex;
                align-items: center;

                input {
                    border: none;
                    font-size: 14px;
                    border-radius: 0;
                    text-align: left;
                    border-bottom: solid 1px #000;

                    &::placeholder {
                        font-size: 14px;
                        font-style: italic;
                        font-weight: normal;
                    }
                }

                label {
                    flex: 0 0 20rem;
                    margin: 0;
                    width: 100%;
                    font-size: 14px;
                    text-align: left;
                }
            }
        }

        .header {
            display: flex;
            justify-content: space-around;
            color: #1E1E1E;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            background: #D3D3D3;
        }

        .acceptanceInspection {
            gap: 50px;
            padding: 25px;
            display: flex;
            height: 266px;
            position: relative;

            .acceptance,
            .inspection {
                width: 50%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .dateReceived,
                .dateInspected {
                    display: flex;

                    label {
                        flex: 0 0 5.5rem;
                    }
                }

                label {
                    color: #1E1E1E;
                    font-size: 10px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }

                input {
                    border: none;
                    font-size: 13px;
                    border-radius: 0;
                    text-align: left;
                    border-bottom: solid 1px #000;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;

                    &::placeholder {
                        font-weight: normal;
                    }
                }

                .input {
                    width: 100%;
                    display: flex;
                    position: relative;
                    align-items: center;

                    input {
                        background-color: transparent;
                    }

                    .addButton {
                        .addCircle {
                            width: 1rem;
                            height: 1rem;
                            cursor: pointer;
                            fill: white;
                            width: 1rem;
                            height: 1rem;
                            border-radius: 50%;
                            background-color: #03A900;
                        }
                    }

                    .signatureImage {
                        left: 0;
                        right: 0;
                        z-index: -1;
                        width: 7rem;
                        margin: auto;
                        position: absolute;
                    }

                    .requestedBy,
                    .approvedBy {
                        width: 100%;
                        margin: 0 0 1px;
                    }
                }

                input[type=checkbox] {
                    width: 21px;
                    height: 21px;
                    margin-right: 5px;
                    border-radius: 2px;
                    background: #FFF;
                    border: 1px solid #878787;
                }

                input[type=checkbox]:checked {
                    background: #878787;
                }
            }
        }

        .buttons {
            gap: 10px;
            width: 100%;
            height: 69px;
            display: flex;
            align-items: center;
            background: #E6E6E6;
            justify-content: center;
            border-radius: 0px 0px 10px 10px;

            .create {
                font-size: 14px;
                border-color: #03A900;
                background-color: #03A900;
            }

            .cancel {
                font-size: 14px;
                border-color: #FF5353;
                background-color: #FF5353;
            }
        }
    }

    &.active {
        left: 0;
        right: 0;
        opacity: 1;
        margin: auto;
        height: auto;
        visibility: visible;
        transition: height 0.3s opacity 0.3s, visibility 0.3s;
    }
}