.profile {
  padding: 1rem 0;
  background-color: #F1F1F1;
  min-height: calc(100vh - 183px);

  .back {
    gap: 10px;
    width: 13rem;
    display: flex;
    color: #fff;
    font-size: 13px;
    font-weight: 500;
    padding: 7px 20px;
    border-radius: 5px;
    align-items: center;
    background: #878787;
    text-decoration: none;
    justify-content: center;

    svg {
      width: 22px;
      height: 22px;
    }
  }

  .profileContainer {
    // max-width: 800px;
    max-width: 85vw;
    margin-top: 1rem;
    border-radius: 10px;
    background-color: #fff;

    .header {
      padding: 1rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #DCDCDC;

      h5 {
        margin: 0;
        font-size: 20px;
        font-weight: 600;
        color: #1E1E1E;
      }

      .selectContainer {
        position: relative;

        .select {
          width: 7rem;
          cursor: pointer;
          appearance: none;
          color: #000000;
          box-shadow: none;
          border-radius: 5px;
          background: #ECECEC;

          &:active,
          &:focus {
            box-shadow: none;
            border: none;
          }
        }

        &::after {
          width: 9px;
          top: 0;
          bottom: 0;
          content: '';
          height: 6px;
          right: 15px;
          margin: auto;
          position: absolute;
          background-size: cover;
          background: url('../../assets/images/arrowdown.svg');
        }
      }


    }

    .body {
      display: flex;
      padding: 1rem;

      .image {
        flex: 0 0 40%;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .imageFile {
          // height: 10rem;
          height: 50%;

          div,
          label {
            height: 100%;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 18px;
          }
        }

        .buttons {
          gap: 10px;
          display: flex;

          button {
            flex: 1;
            max-width: 50%;
            border: none;

            &.edit {
              font-size: 15px;
              font-weight: 500;
              color: #FFFFFF;
              border-radius: 5px;
              background: #03A900;
            }

            &.remove {
              font-size: 15px;
              font-weight: 500;
              color: #FFFFFF;
              border-radius: 5px;
              background: #FF8E8E;
            }
          }
        }
      }

      .form {
        flex: 0 0 60%;
        padding: 0 2rem;

        .group {
          display: flex;
          align-items: center;

          @media only screen and (max-width: 991px) {
            flex-direction: column;
            align-items: flex-start;
          }

          label {
            margin: 0;
            width: 10rem;
            font-size: 15px;
            font-weight: 500;
            color: #878787;
          }

          input,
          select {
            width: 100%;
            border: none;
            font-size: 15px;
            font-weight: 600;
            color: #1E1E1E;
            background-color: transparent;

            @media only screen and (max-width: 767px) {
              width: 75%;
            }
            @media only screen and (max-width: 540px) {
              width: 65%;
            }

            &:active,
            &:focus {
              border: none;
              box-shadow: none;
            }

            &.editing {
              border-radius: 0;
              border-bottom: solid 1px #1E1E1E;
            }
          }
        }

        .signatureGroup {
          gap: 1rem;
          display: flex;
          flex-direction: column;

          label {
            margin: 0;
            width: 10rem;
            font-size: 15px;
            font-weight: 500;
            color: #878787;
          }

          .signatureContainer {
            width: 100%;
            cursor: pointer;
            min-height: 10rem;
            border: solid 1px #999;

            @media only screen and (max-width: 767px) {
              width: 75%;
            }
            @media only screen and (max-width: 540px) {
              width: 65%;
            }

            .signature{
              width: 100%;
            }
          }
        }
      }
    }
  }
}