$screen-lg: 992px;
$screen-md: 767px;
$screen-sm: 540px;
$screen-xs: 490px;

// Extra Small devices
@mixin xs {
  @media (max-width: #{$screen-xs}) {
    @content;
  }
}

// Small devices
@mixin sm {
  @media (max-width: #{$screen-sm}) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media (max-width: #{$screen-md}) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (max-width: #{$screen-lg}) {
    @content;
  }
}



.inventory {
  padding: 1rem 0;
  background-color: #F1F1F1;
  min-height: calc(100vh - 183px);

  .columm {
    position: relative;

    .profileContainer {
      margin-top: 1rem;
      border-radius: 10px;
      background-color: #fff;

      .header {
        padding: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #DCDCDC;

        @include md {
          flex-direction: column;
          gap: 1rem;
        }

        .search {
          flex: 1;
          gap: 1rem;
          display: flex;
          align-items: center;

          @include md {
            flex-direction: column;
            align-items: flex-start;
            align-self: flex-start;
            width: 100%;
          }

          h5 {
            margin: 0;
            font-size: 20px;
            font-weight: 600;
            color: #1E1E1E;
          }
        }

        .filter {
          display: grid;
          grid-template-columns: auto auto auto auto auto;
          column-gap: 10px;

          @media screen and (max-width: 1400px) {
            grid-template-columns: auto auto auto;
            row-gap: 10px;
          }

          .from,
          .to {
            gap: 1rem;
            display: flex;
            font-size: 13px;
            font-weight: 500;
            color: #1E1E1E;
            font-style: normal;
            align-items: center;

            p {
              gap: 10px;
              margin: 0;
              display: flex;
              align-items: center;

              svg {
                width: 36px;
                height: 36px;
                border-radius: 5px;
                background: #ECECEC;
              }
            }

            input {
              height: 36px;
              font-size: 13px;
              color: #878787;
              font-weight: 500;
              border-radius: 5px;
              background: #ECECEC;
            }
          }

          .searchBar {
            
            @media screen and (max-width: 1400px) {
              grid-column-start: 1;
              grid-column-end: 3;
            }

            position: relative;

            @include md {
              width: 100%;
            }

            svg {
              top: 0;
              left: 10px;
              bottom: 0;
              margin: auto;
              position: absolute;
            }

            input {
              height: 33.5px;
              font-size: 10px;
              color: #878787;
              padding-left: 2rem;
              font-family: 'Poppins';
            }
          }

          .requestSettings {
            @include md {
              width: 100%;
            }

            gap: 10px;
            display: flex;
            flex: 0 0 10rem;
            font-size: 13px;
            font-weight: 500;
            color: #FFFFFF;
            border-radius: 5px;
            align-items: center;
            background: #03A900;
            border-color: #03A900;
          }

          .requestButton {
            font-size: 10px;
            font-weight: 500;
            color: #1E1E1E;
          }

          .selectContainer {

            @media screen and (max-width: 1400px) {
              grid-column-start: 3;
              grid-column-end: 4;
              grid-row-start: 1;
              grid-row-end: 2;
            }

            position: relative;

            .select {
              width: 100%;
              cursor: pointer;
              appearance: none;
              color: #000000;
              box-shadow: none;
              border-radius: 5px;
              background: #ECECEC;
              font-size: 13px;
              font-weight: 500;
              color: #878787;

              @include md {
                width: 100%;
                height: fit-content;
              }

              &:active,
              &:focus {
                box-shadow: none;
                border: none;
              }
            }

            &::after {
              width: 9px;
              top: 0;
              bottom: 0;
              content: '';
              height: 6px;
              right: 15px;
              margin: auto;
              position: absolute;
              background-size: cover;
              background: url('../../assets/images/arrowdown.svg');
            }
          }
        }
      }

      .body {
        gap: 1.5rem;
        display: flex;
        padding: 1rem;
        flex-direction: column;

        .processTable {
          margin: 0;

          thead {
            border: none;

            tr {
              border: none;

              th {
                white-space: nowrap;
                border: none;
                font-size: 15px;
                font-weight: 500;
                color: #878787;
              }
            }
          }

          tbody {
            tr {
              td {
                font-size: 15px;
                font-weight: 600;
                color: #1E1E1E;
                vertical-align: middle;

                img {
                  width: 5rem;
                }

                span {
                  color: #fff;
                  display: block;
                  padding: 5px 10px;
                  width: max-content;
                  background: blue;
                  text-align: center;
                  border-radius: 18px;
                  text-transform: capitalize;

                  &.approved {
                    font-size: 14px;
                    font-weight: 600;
                    color: #03A900;
                    border: 1px solid #03A900;
                    background: rgba(3, 169, 0, 0.04);
                  }

                  &.forApproval,
                  &.Pending {
                    color: #C3A400;
                    font-size: 14px;
                    font-weight: 600;
                    background: #FFF5BF;
                    border: 1px solid #D9B600;
                  }

                  &.rejected {
                    font-size: 14px;
                    color: #FF0000;
                    font-weight: 500;
                    border: 1px solid #F00;
                    background: rgba(255, 142, 142, 0.16);
                  }
                }
              }

              td.actions {
                gap: 5px;
                display: flex;
                align-items: center;

                button {
                  gap: 5px;
                  border: none;
                  display: flex;
                  font-size: 14px;
                  align-items: center;
                  border-radius: 18px;

                  &.viewPR {
                    background: #03A900;
                  }

                  &.createPO {
                    background: #4462FF;
                  }

                  &.rejectPR {
                    background: #FF8E8E;
                  }

                  &.actionPR {
                    background-color: #4462FF;
                  }
                }
              }
            }
          }

          tfoot {
            border-bottom: none;

            tr {
              border-bottom: none;

              td {
                padding-top: 1rem;
                border-bottom: none;
              }
            }
          }
        }
      }
    }
  }

  &.show {
    padding-bottom: 25rem;
  }
}