.remarks {
    top: 10rem;
    opacity: 0;
    right: 1rem;
    z-index: 100;
    width: 700px;
    height: 10rem;
    padding: 1rem;
    overflow-y: hidden;
    position: absolute;
    visibility: hidden;
    border-radius: 20px;
    background: #FFFFFF;
    box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.25);
    transition: height 0.3s opacity 0.3s, visibility 0.3s;

    .header {
        padding: 0;
        display: flex;
        margin: 0 0 1rem;
        position: relative;
        align-items: center;
        justify-content: space-between;

        p {
            margin: 0;
            font-weight: 600;
            color: #000000;
        }

        .close {
            z-index: 99;
            width: 13px;
            height: 13px;
            cursor: pointer;
            position: relative;
        }
    }

    .body {
        gap: 1rem;
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        .group {
            width: 100%;

            textarea {
                background: #F3F3F3;
            }
        }

        .submit {
            width: 10rem;
            color: #fff;
            font-size: 14px;
            border-radius: 10px;
            background: #022BFF;
        }
    }

    &.active {
        left: 0;
        right: 0;
        opacity: 1;
        margin: auto;
        height: auto;
        visibility: visible;
        transition: height 0.3s opacity 0.3s, visibility 0.3s;
    }
}