.processTable {
    margin: 0;

    thead {
        border: none;

        tr {
            border: none;

            th {
                border: none;
                font-size: 15px;
                font-weight: 500;
                color: #878787;
                white-space: nowrap;
            }
        }
    }

    tbody {
        tr {
            td {
                font-size: 15px;
                font-weight: 600;
                color: #1E1E1E;
                vertical-align: middle;

                img {
                    width: 5rem;
                    height: 5rem;
                    object-fit: cover;
                }

                .tableButtons {
                    gap: 1rem;
                    display: flex;
                    align-items: center;

                    button {
                        color: #fff;
                        display: block;
                        font-size: 13px;
                        padding: 5px 14px;
                        width: max-content;
                        background: blue;
                        text-align: center;
                        border-radius: 18px;
                        font-weight: bold;

                        &.activate {
                            background: #03A900;
                            border-color: #03A900;
                        }

                        &.deactivate {
                            background: #FF8181;
                            border-color: #FF8181;
                        }
                    }
                }

                span {
                    color: #fff;
                    display: block;
                    font-size: 13px;
                    padding: 5px 14px;
                    width: max-content;
                    background: blue;
                    text-align: center;
                    border-radius: 18px;

                    &.Pending {
                        color: #EDC700;
                        text-transform: capitalize;
                        border: 1px solid #EDC700;
                        background: rgba(255, 215, 2, 0.1);
                    }

                    &.Declined {
                        color: #FF0000;
                        text-transform: capitalize;
                        border: 1px solid #FF0000;
                        background: rgba(255, 142, 142, 0.35);
                    }
                }
            }
        }
    }

    tfoot {
        border-bottom: none;

        tr {
            border-bottom: none;

            td {
                padding-top: 1rem;
                border-bottom: none;
            }
        }
    }
}