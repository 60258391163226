.top-bar {
    z-index: 5;
    gap: 10px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: 991px) {
        align-items: flex-start;
        justify-content: flex-start;
        .container{
            height: 100px;
            position: relative;
            .hamburgerBtn{
                position: absolute;
                bottom: 40%;
                border-style: none;
            }
            .openedHamburgerMenu{
                position: absolute;
                top: 60%;
                width: 25vw;
            }
        }
    }

    .nav-link {
        gap: 10px;
        display: flex;
        font-size: 15px;
        font-weight: 500;
        color: #878787;
        position: relative;
        align-items: center;

        svg {
            fill: #878787;
        }

        &.active {
            color: #022BFF;

            svg {
                fill: #022BFF;
            }

            &::after {
                content: '';
                width: 100%;
                height: 6px;
                bottom: -27px;
                position: absolute;
                background-color: #022BFF;

                @media only screen and (max-width: 991px) {
                    bottom: 0px;
                }
            }
        }
    }
}

.background-gray {
    background-color: #F1F1F1;
    width: 100%;

    .dashboard-account {
        display: flex;
        padding: 1rem 0;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #DCDCDC;

        .left-container {
            gap: 10px;
            display: flex;
            align-items: center;

            img {
                width: 48px;
            }

            .line {
                width: 0.6px;
                height: 44px;
                background-color: #1E1E1E;
            }

            p {
                margin: 0;
                font-weight: 600;
                max-width: 10rem;
                font-size: 12.0709px;

                span {
                    display: block;
                }
            }
        }

        .right-container {
            gap: 10px;
            display: flex;
            align-items: center;

            .bell {
                cursor: pointer;
            }

            .account-settings {
                padding: 0;
                border: none;
                font-size: 15px;
                color: #1E1E1E;
                font-weight: 500;
                background-color: transparent;

                img {
                    width: 3rem;
                    height: 3rem;
                    margin-right: 5px;
                    border-radius: 50%;
                }
            }

            .selections {
                border: none;
                border-radius: 10px;
                box-shadow: 3px 5px 10px 5px rgba(0, 0, 0, 0.1);

                a {

                    &:hover,
                    &:active,
                    &:focus,
                    &.active {
                        color: #FF0000;
                        background-color: #fff;
                    }
                }
            }
        }
    }
}



@media (max-width: 1400px) {
    
    body {
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        
    
        .background-gray {
              padding-top: 10px;
              margin: auto;
              position: relative;
        }
  
      }
}

// @media (max-width: 991px) {
  
//     body {
//         text-align: center;
//         display: flex;
//         justify-content: center;
//         align-items: center;
        
    
//         .background-gray {
//               padding-top: 10px;
//               margin: auto;
//               position: relative;
//               left: 100px;
//         }
  
//       }
//   }
  

// /* For screens 7761px and below */
// @media (max-width: 767px) {
    
//     body {
 
//         .background-gray {
//             left: 200px;
//         }
  
//       }
// }


// /* For screens 7761px and below */
// @media (max-width: 540px) {
  
//     .top-bar {
//         position: relative;
//         left: 380px;
//     }
//     body {

//       .background-gray {
//             width: 115%;
//             left: 200px;
//             padding-left: 20%;
//             padding-right: 5%;
//       }

//     }

//   }